<template>
    <div>
        <div class="header">
            <van-nav-bar :title="$t('tix.title')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <van-form v-model="form">
                <van-row>
                    <!--          <van-cell :title="$t('tix.biz')" center>-->
                    <!--            <template #extra>-->
                    <!--              <van-dropdown-menu active-color="#004ea3">-->
                    <!--                <van-dropdown-item v-model="bizhong" :options="bzoptions" />-->
                    <!--              </van-dropdown-menu>-->
                    <!--            </template>-->
                    <!--          </van-cell>-->
                    <van-cell :title="$t('tix.lname')" center>
                        <template #extra>
                            <van-dropdown-menu active-color="#004ea3">
                                <van-dropdown-item v-model="productvalue" :options="product" />
                            </van-dropdown-menu>
                        </template>
                    </van-cell>
                    <van-field v-model="form.url" :disabled="isAddress" :label="$t('tix.address')"
                        :placeholder="$t('tix.enter')" />
                    <van-field :label="$t('tix.number')" v-model="form.number" clearable><template #button>
                            <van-button size="small" type="primary" @click.prevent="all">{{
                                $t('tix.allbtn')
                            }}</van-button>
                        </template>
                    </van-field>
                    <div style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;">
                        <span style="color: rgb(66, 76, 102);">{{ $t('tix.text') }}{{ formatMoney(balance) }}</span>
                    </div>
                </van-row>
                <van-button class="submitBtns" @click.prevent="submit">{{
                    $t('common.submit')
                }}</van-button>
            </van-form>
        </div>
    </div>
</template>
<script>
import {formatNumberWith} from "@/utils";
export default {
    data() {
        return {
            form: {
                url: '',
                number: ''
            },
            productvalue: 0,
            product: [],
            bizhong: 1,
            bzoptions: [{ text: 'USDT', value: 1 }],
            balance: 0,
            huilv: 0,
            config: {},
            type: '',
            cnynum: 0,
            product_type: '',
            product_type_id: '',
            isBank: false,
            isAddress: false,
            infos:{}
        }
    },
    watch:{
        productvalue(v,o){
            if(v == 0 && this.infos.withdraw_url){
                this.isAddress = true
                this.form.url = this.infos.withdraw_url
            }else if(v == 1 && this.infos.withdraw_url2){
                this.isAddress = true
                this.form.url = this.infos.withdraw_url2
            }else{
                this.isAddress = false;
                this.form.url = "";
            }
        }
    },
    created() {
        this.getinfo()
    },
    methods: {
        formatMoney:function(m){
            return formatNumberWith(m)
        },
        async getinfo() {
            const { data } = await this.$http.get('/home/user/withdraw')
            if (data) {
                if (data.code === 200) {
                    // if (data.data.info.bankcard) {
                    //     this.isBank = true
                    //     this.form.bank = data.data.info.bank
                    //     this.form.bankcard = data.data.info.bankcard
                    //     this.form.name = data.data.info.name
                    // }
                    this.infos = data.data.info;
                    if (data.data.info.withdraw_url && this.productvalue == 0) {
                        this.isAddress = true
                        this.form.url = data.data.info.withdraw_url
                    }else if(data.data.info.withdraw_url2 && this.productvalue == 1){
                        this.isAddress = true
                        this.form.url = data.data.info.withdraw_url2
                    }

                    this.huilv = data.data.huilv
                    this.balance = data.data.balance
                    this.wallet = data.data.type
                    this.config = data.data.config
                    this.product = []
                    this.wallet.forEach((type, index) => {
                        this.product.push({
                            text: type.wallet_type.toUpperCase(),
                            value: index,
                            id:type.id
                        })
                    })
                    if (this.config.withdrawl_opend === '0') {
                        this.$toast.fail(this.$t('tix.erroropen'))
                    }
                    if (!this.config.attime) {
                        this.$toast.fail(
                            this.$t('tix.errortime') + this.config.withdrawl_times
                        )
                    }
                }
            }
        },
        cnynumjs() {
            this.cnynum = this.form.number * this.huilv
        },
        all() {
            this.form.number = this.balance
            this.cnynum = this.form.number * this.huilv
        },
        async submit() {
            if (!this.form.number) {
                this.$toast.fail(this.$t('tix.complate'))
                return false
            }

           // 组装数据
            this.product_type = 'USDT'
            this.product_type_id = '1'
            this.form.bank = ''
            this.form.bankcard = ''
            this.form.name = ''
            const postform = {
                type: 0,
                product_type: this.product_type,
                product_type_id: this.product_type_id,
                withdraw_url: this.form.url,
                withdraw_num: this.form.number,
                service_charge: this.config.withdrawl_fee,
                uname: this.form.name,
                wallet_id:this.product[this.productvalue].id

            }
            const { data } = await this.$http.post('/home/user/withdraw', postform)
            if (data) {
                if (data.code === 200) {
                    this.$toast.success(this.$t('tix.submitok'))
                    this.form = {
                        url: '',
                        number: 0
                    }
                    this.getinfo()
                } else {
                    this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.maincontent {
    padding-top: 65px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 0.93333rem;
    background: #f7f7f7;
    height: 100vh;

    .van-cell__title {
        text-align: left;
        font-size: 0.93333rem;
    }

    .van-cell {
        font-size: 0.98667rem;
        background-color: #fff !important;
        height: 3.46667rem;

        &:after {
            border-bottom: 0.02667rem solid #d0d0d1;
        }
    }

    .van-dropdown-menu /deep/.van-dropdown-menu__bar {
        box-shadow: none;
        height: 1rem;
        background: transparent !important;
    }

    .van-dropdown-menu /deep/ .van-dropdown-menu__title {
        color: #212121 !important;
    }

    /deep/.van-dropdown-item {
        margin-top: 1.1rem;
    }

    .van-field {
        height: 3.4rem;
        font-size: 0.93333rem;

        /deep/.van-field__label {
            color: #323232;
        }
    }

    .van-cell__value {
        position: relative;
        overflow: hidden;
        color: #969799;
        text-align: right;
        vertical-align: middle;
        word-wrap: break-word;
    }

    /deep/.van-field__error-message--right,
    /deep/.van-field__control,
    /deep/.van-field__error-message {
        text-align: right;
    }

    .message {
        margin-top: 0.8rem;
        font-size: 1.06667rem;
        color: #000;
    }

    .submitBtns {
        margin-top: 10%;
        width: 80%;
        letter-spacing: 0.53333rem;
        height: 4rem;
        font-size: 0.93333rem;
        color: #fff;
        background-color: #004ea3;
        border-radius: 0.53333rem;
        border: none;
        box-shadow: 0 0 1.33333rem #004ea3;
    }
}
</style>
